<template>
  <div class="lgc-module lgc-module-title was-contenteditable">
    <h2>{{ value }}</h2>
  </div>
</template>

<script>
export default {
  name: "LgcModuleTitle",
  props: {
    value: String
  }
}
</script>

<style scoped>

</style>
