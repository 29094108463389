<template>
  <div class="container">
    <div class="page" v-if="jsonData">
      <div class="element" :key="jsonData.pages[pageIdx].id+'-'+element.id" v-for="element in jsonData.pages[pageIdx].elements">
        <component
            v-bind:is="element.type"
            v-bind="element.props"
            :page="computedPageId"
            @click="nextPage"
            @update="updateAnswers"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { cloudApiBaseUrl } from "@/constants";
import LgcModuleTitle from "@/components/modules/LgcModuleTitle";
import LgcModuleText from "@/components/modules/LgcModuleText";
import LgcModuleQuiz from "@/components/modules/LgcModuleQuiz";
import LgcModuleImage from "@/components/modules/LgcModuleImage";
import LgcModulePersonalInfo from "@/components/modules/LgcModulePersonalInfo";
import LgcModuleButton from "@/components/modules/LgcModuleButton";
import LgcModuleChoices from "@/components/modules/LgcModuleChoices";
import LgcModuleInput from "@/components/modules/LgcModuleInput";
import LgcModuleInputRange from "@/components/modules/LgcModuleInputRange";

export default {
  name: 'Leadgenerator',
  components: {
    LgcModuleTitle,
    LgcModuleText,
    LgcModuleQuiz,
    LgcModuleImage,
    LgcModulePersonalInfo,
    LgcModuleButton,
    LgcModuleChoices,
    LgcModuleInput,
    LgcModuleInputRange
  },
  props: {
    // jsonData: Object
  },
  computed: {
    computedIframeKey() {
      return this.$route.params.iframeKey
    },
    computedLeadgenId() {
      return this.$route.params.leadgenId
    },
    computedPageId() {
      return parseInt(this.$route.params.pageId) || 1
    },
    pageIdx() {
      return this.computedPageId -1
    }
  },
  data() {
    return {
      jsonData: null,
      answers: {},
      personalInfo: {}
    }
  },
  created() {
    fetch(cloudApiBaseUrl + `/iframe/leadgenerator/${this.computedLeadgenId}/json?key=${this.computedIframeKey}`)
      .then(response => response.json())
      .then(json => {
        this.jsonData = json
        document.title = json.metadata.name
        console.log(json)
        this.addFavicon(json)
        this.addFont(json)
        this.addGTM(json)
        this.addFacebookPixel(json)
        this.addCustomHeader(json)
        this.trackStep(json.pages[0].name)
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err) // better error handling
      })
  },
  methods: {
    nextPage(value, typeOfAppend) {
      const currentPageId = this.computedPageId
      const newPageId = currentPageId +1
      this.updateAnswers(value, typeOfAppend)
      if (this.jsonData.pages.length >= newPageId) {
        this.$router.push({
          name: 'Leadgenerator',
          params: {
            leadgenId: this.$route.params.leadgenId,
            pageId: String(newPageId)
          }
        })
      }
    },
    updateAnswers(value, typeOfAppend) {
      const currentPageId = this.computedPageId
      const currentPage = this.jsonData.pages[currentPageId -1]
      if (typeOfAppend === 'personalInfo') {
        this.personalInfo = { ...value }
      } else {
        switch (typeof value) {
          case 'string':
          case 'number':
            this.answers[currentPage.name] = value
            break;
          case 'object':
            this.answers = {...this.answers, ...value}
            break;
          default:
            console.error('Answers are not string or obejct. Can\'t append.')
        }
      }
      console.log(JSON.stringify(this.answers))
    },
    addFavicon(json) {
      if (json.metadata.faviconUrl) {
        const favicon = document.getElementById('favicon')
        favicon.href = json.metadata.faviconUrl
      }
    },
    addFont(json) {
      const fontFamily = json.settings?.font || 'Open Sans'
      const fontFamilyId = fontFamily.toLowerCase().replaceAll(' ', '-')
      let file = document.createElement('link');
      file.rel = 'stylesheet';
      file.href = process.env.VUE_APP_CDN_URL + `/fonts/css/${fontFamilyId}.css`
      document.head.appendChild(file)
      document.getElementById('app').style.fontFamily = fontFamily
    },
    addGTM(json) {
      if (json.metadata.tracking && json.metadata.tracking.googleTagManager) {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer',json.metadata.tracking.googleTagManager);
      }
    },
    addFacebookPixel(json) {
      if (json.metadata.tracking && json.metadata.tracking.facebookPixel) {
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', json.metadata.tracking.facebookPixel);
        fbq('track', 'PageView');
        fbq('track', 'ViewContent');
      }
    },
    addCustomHeader(json) {
      if (json.metadata.tracking && json.metadata.tracking.customHeader) {
        document.head.insertAdjacentHTML('beforeend', json.metadata.tracking.customHeader);
      }
    },
    createBasicLead() {
      if (window.prtAlreadyCreatedBasicLead) {
        console.log('Ignoring... window.prtAlreadyCreatedBasicLead is set to true.')
        return
      }
      if (!this.personalInfo.email) {
        console.log('Ignoring... email is not set.')
        return
      }
      const data = {
        holder: {
          salutation: this.personalInfo.salutation || '',
          firstName: this.personalInfo.firstName || '',
          lastName: this.personalInfo.lastName || '',
          phoneNumber: this.personalInfo.phoneNumber || '',
          email: this.personalInfo.email
        },
        extraSteps: []
      }
      let order = 0
      for (const [key, value] of Object.entries(this.answers)) {
        data.extraSteps.push({
          order: order++,
          title: key,
          answer: value,
          change: 0,
          changeType: 'FIXED'
        })
      }
      console.log(data)
      fetch(process.env.VUE_APP_CLOUD_API_BASE_URL + `/iframe/leadgenerator/${this.$route.params.leadgenId}/answer?key=${this.computedIframeKey}`, {
        method: 'POST',
        referrerPolicy: 'origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        console.log('Successfully created:', data)
        window.prtAlreadyCreatedBasicLead = true
        this.trackFinished(data.id) // leadId
      })
      .catch(err => {
        console.error('Error:', err)
      })
    },
    trackStep(step) {
      console.log('track', step)
      if (typeof ga !== 'undefined') ga('send', 'event', 'PRTStep', 'click', step)
      if (typeof fbq !== 'undefined') fbq('trackCustom', 'PRTStep', { action: 'click', label: step })
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          'event': 'prt_step',
          'action': 'click',
          'label': step
        })
      }
    },
    trackFinished(leadId) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          'event': 'generate_lead',
          'value': '50',
          'currency': 'EUR',
          'transaction_id': leadId
        })
      }
      if (typeof ga !== 'undefined') {
        ga('send', 'event', 'generate_lead', 'click', leadId)
      }
      if (typeof fbq !== 'undefined') {
        fbq('track', 'Lead', {
          value: 50,
          currency: 'EUR',
          external_id: leadId
        })
      }
    },
  },
  watch: {
    pageIdx(currentIdx) {
      const lastIdx = this.jsonData.pages.length -1
      console.log(lastIdx, currentIdx)
      const currentPage = this.jsonData.pages[currentIdx]
      this.trackStep(currentPage.name)
      if (currentIdx >= lastIdx) {
        this.createBasicLead()
      }
    }
  }
}
</script>

<style lang="scss">
.was-contenteditable {
  display: inline-block;
  white-space: pre-line;
}
</style>
