<template>
  <div class="lgc-module lgc-module-input">
    <label v-text="dataInputText"></label>
    <input @focusout="$emit('update', $event.target.value)" type="text" name="module-input" :placeholder="computedPlaceholder" :required="dataInputRequired"/>
  </div>
</template>

<script>
export default {
  name: "LgcModuleInput",
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      dataInputText: this.value.inputText,
      dataInputPlaceholder: this.value.inputPlaceholder,
      dataInputRequired: this.value.inputRequired || true
    }
  },
  computed: {
    computedPlaceholder() {
      return this.dataInputPlaceholder + (this.dataInputRequired ? "*" : "")
    }
  },
}
</script>

<style lang="scss" scoped>
.lgc-module-input {
  margin: 0;
  padding: 10px 20px;

  input {
    max-width: 100%;
    width: 100%;
    padding: 12px 14px;
    margin: -1px;
    border: 1px solid #bfbfbf;
    outline: 0;
    font-size: 16px;
    line-height: normal;
    border-radius: 4px;
  }
}
</style>
