<template>
  <div>
    <div class="lgc-module lgc-module-button">
      <button
          class="was-contenteditable"
          @click="$emit('click', {})"
          type="button"
          v-text="dataButtonText"
          :style="{ backgroundColor: this.dataButtonColor, borderColor: this.dataButtonColor }"
      >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LgcModuleButton",
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      dataButtonText: this.value.buttonText,
      dataButtonColor: this.value.buttonColor || '#2d78a9'
    }
  }
}
</script>

<style lang="scss" scoped>
.lgc-module-button {
  margin: 0;

  button {
    margin: 12px 0;
    border: 1px solid #1c6ca1;
    background-color: #2d78a9;
    color: #fff;
    font-size: 20px;
    padding: 6px 14px;
    border-radius: 4px;
    outline: 0;

    &:hover {
      border: 1px solid #1d608c;
      background-color: #226694;
    }
  }
}
</style>
