<template>
  <div class="lgc-module lgc-module-quiz">
    <div @click="$emit('click', card.title)" :key="'quiz-' + page + '-' + card.id" class="lgc-module-quiz--card" v-for="card in cards">
      <div class="lgc-module-quiz--card-body">
        <div class="lgc-module-quiz--image"><img :src="card.image" alt="Bild hinzufügen..."/></div>
        <div class="lgc-module-quiz--title was-contenteditable">{{ card.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LgcModuleQuiz",
  components: {},
  props: {
    page: [Number, String],
    value: Array
  },
  data() {
    return {
      cards: this.value
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
.lgc-module-quiz {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 14px;
  row-gap: 14px;
  column-gap: 14px;

  .lgc-module-quiz--card {
    position: relative;
    display: flex;
    width: 46%;
    min-height: 200px;
    max-height: 200px;
    //border: 1px solid #333;
    border-radius: 8px;
    flex-direction: column;
    // overflow: hidden;
    transition: 0.1s all ease-in-out;
    cursor: pointer;

    &:hover {
      // transform: scale3d(1.02, 1.02, 1.02);
      transform: scale(1.02);
    }
  }
  .lgc-module-quiz--card-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #333;
  }
  .lgc-module-quiz--image {
    position: relative;
    height: 75%;
    cursor: pointer;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-drag: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
    }
  }
  .lgc-module-quiz--title {
    outline: none;
    width: 100%;
    height: 25%;
    font-size: 18px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 4px 0;
  }
}
</style>
