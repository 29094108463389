<template>
  <div class="lgc-module lgc-module-choices">
    <div
        class="lgc-module-choices--choice"
        @click="$emit('click', choice.title)"
        :key="'choice-' + page + '-' + choice.id"
        v-for="choice in choices"
    >
      <div class="lgc-module-choices--choice-body">
        <div class="lgc-module-choices--title" v-text="choice.title"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LgcModuleChoices",
  data() {
    return {
      choices: this.value,
    }
  },
  props: {
    page: [Number, String],
    value: Array
  }
}
</script>

<style lang="scss">
.lgc-module-choices {
  width: 100%;

  .lgc-module-choices--choice {
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 6px 0;
    border-radius: 8px;
    transition: 0.1s all ease-in-out;
    cursor: pointer;
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;
    &:hover {
      transform: scale(1.02);
    }
  }
  .lgc-module-choices--choice-body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #333;
    transition: 0.1s all ease-in-out;
    &:hover {
      border: 2px solid #333;
    }
  }
  .lgc-module-choices--title {
    outline: none;
    width: 100%;
    height: 25%;
    font-size: 18px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 4px 0;
  }
}
</style>
