<template>
  <div class="lgc-module lgc-module-personalinfo">
    <p class="was-contenteditable">{{ dataHeader }}</p>
    <form ref="form">
      <input v-model="form.salutation" type="text" name="salutation" placeholder="Anrede*" autocomplete="honorific-prefix salutation" required/>
      <input v-model="form.firstName" type="text" name="firstName" placeholder="Vorname*" autocomplete="given-name" required/>
      <input v-model="form.lastName" type="text" name="lastName" placeholder="Nachname*" autocomplete="family-name" required/>
      <input v-model="form.email" type="text" name="email" placeholder="E-Mail*" autocomplete="email" required/>
      <input v-model="form.phoneNumber" type="text" name="phoneNumber" :placeholder="'Telefonnummer' + (dataPhoneNumberRequired ? '*' : '')" autocomplete="tel" :required="dataPhoneNumberRequired" />
    </form>
    <p v-if="value.conditionsPrivacyActive " :style="{ fontSize: value.conditionsPrivacyTextSize }" class="conditions-privacy" v-html="computedConditionsPrivacyText"/>
    <button
        @click="submit"
        class="was-contenteditable"
        type="button"
        :style="{ backgroundColor: this.dataButtonColor, borderColor: this.dataButtonColor }"
    >
      {{ dataButtonText }}
    </button>
  </div>
</template>

<script>
import {cloudApiBaseUrl} from "@/constants";

export default {
  name: "LgcModulePersonalInfo",
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      dataHeader: this.value.header,
      dataButtonText: this.value.buttonText,
      dataButtonColor: this.value.buttonColor || '#2d78a9',
      dataPhoneNumberRequired: typeof this.value.phoneNumberRequired === 'boolean' ? this.value.phoneNumberRequired : true,
      form: {
        salutation: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    }
  },
  computed: {
    computedIframeKey() {
      return this.$route.params.iframeKey
    },
    computedConditionsPrivacyText() {
      const iframeKey = this.computedIframeKey
      const conditionsLink = cloudApiBaseUrl + "/iframe/conditions/" + iframeKey
      const privacyLink = cloudApiBaseUrl + "/iframe/privacy/" + iframeKey
      let computedText = this.value.conditionsPrivacyText + ""
      computedText = computedText.replace('Allgemeinen Geschäftsbedingungen', `<a target="_blank" href="${conditionsLink}">Allgemeinen Geschäftsbedingungen</a>`)
      computedText = computedText.replace('AGB', `<a target="_blank" href="${conditionsLink}">AGB</a>`)
      computedText = computedText.replace('Datenschutzerklärung', `<a target="_blank" href="${privacyLink}">Datenschutzerklärung</a>`)
      return computedText
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.checkValidity()) {
        this.$emit('click', {
          ...this.form
        }, 'personalInfo')
      } else {
        this.$refs.form.reportValidity()
      }
    }
  }
}
</script>

<style lang="scss">
.lgc-module-personalinfo {
  padding: 10px 20px;
  h2 {
    margin: 0;
  }
  input {
    max-width: 100%;
    width: 100%;
    padding: 10px 14px;
    margin: -1px;
    border: 1px solid #bfbfbf;
    outline: 0;
    font-size: 16px;
    &:first-of-type {
      border-radius: 4px 4px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 4px 4px;
    }
  }
  button {
    margin-top: 18px;
    border: 1px solid #1c6ca1;
    background-color: #2d78a9;
    color: #fff;
    font-size: 20px;
    padding: 6px 14px;
    border-radius: 4px;
    outline: 0;
    &:hover {
      border: 1px solid #1d608c;
      background-color: #226694;
    }
  }
}
</style>
