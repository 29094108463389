<template>
  <div class="lgc-module lgc-module-input-range">
    <RangeSlider
        :unit="dataInputUnit"
        :value="computedInputDefault"
        :min="computedInputMin"
        :max="computedInputMax"
        :labelEvery="Math.floor(dataInputMax / 2)"
        :show-min-label="true"
        :show-alternative-input="dataInputShowAlternative"
        :font-color="dataInputFontColor"
        :background-color="dataInputBackgroundColor"
        @input="submit"
    />
  </div>
</template>

<script>
import RangeSlider from "@/components/RangeSlider";
import 'vue-slider-component/theme/default.css'

export default {
  name: "LgcModuleInputRange",
  components: {
    RangeSlider,
  },
  props: {
    value: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      modalVisible: false,
      dataInputMin: this.value.inputMin || 0,
      dataInputMax: this.value.inputMax || 100,
      dataInputDefault: this.value.inputDefault || 50,
      dataInputUnit: this.value.inputUnit || 'm²',
      dataInputShowAlternative: this.value.inputShowAlternative || false,
      dataInputFontColor: this.value.inputFontColor || '#fff',
      dataInputBackgroundColor: this.value.inputBackgroundColor || '#2D78A9',
    }
  },
  computed: {
    computedInputMin() {
      return Number.parseInt(this.dataInputMin)
    },
    computedInputMax() {
      return Number.parseInt(this.dataInputMax)
    },
    computedInputDefault() {
      return Number.parseInt(this.dataInputDefault)
    }
  },
  methods: {
    submit(val) {
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.lgc-module-input-range {
  margin: 0;
  padding: 10px 20px;

  input {
    max-width: 100%;
    width: 100%;
    margin: -1px;
    border: 1px solid #bfbfbf;
    outline: 0;
    font-size: 16px;
    line-height: normal;
    border-radius: 4px;
  }
}
</style>
