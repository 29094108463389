<template>
  <div class="lgc-module lgc-module-image">
    <img :src="value" alt="image"/>
  </div>
</template>

<script>
export default {
  name: "LgcModuleTitle",
  props: {
    value: String
  }
}
</script>

<style lang="scss">
.lgc-module.lgc-module-image {
  img {
    border: 0;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
