<template>
  <div class="lgc-module lgc-module-text was-contenteditable">
    <p>{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: "LgcModuleText",
  props: {
    value: String
  }
}
</script>

<style scoped>

</style>
